import * as React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

const Index = ({ pageContext, location }) => {
  const { pages } = pageContext;

  const links = pages.map((page) => {
    // TODO make work local
    return (
      <div>
        <br />
        <Link href={page}>{page.replace(/_/g, " ")}</Link>
      </div>
    );
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        background: "#efe4e8",
        margin: "-10px",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Wikipedia articles in chronological order"}</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta
          name="description"
          content={"Chronoligcal order of any wikipedia article."}
        />
      </Helmet>
      <div style={{ padding: "48px", maxWidth: "80vw", background: "white" }}>
        <h1>Events in chronological order from Wikipedia articles</h1>
        {links}
      </div>
    </div>
  );
};

export default Index;
